<template>
  <v-container :class="{ 'pr-0 pl-0 pt-0': !$vuetify.breakpoint.mdAndUp }">
    <v-row>
      <v-col cols="12" class="py-1">
        <h3 class="text-h5 ml-1 font-weight-medium">
          Ventas
        </h3>
      </v-col>
      <v-col cols="12" class="pt-0">
        <app-total-products
          :loader="loadingGlobal"
          :products="total_products"
          class="mb-2"
        ></app-total-products>
        <app-total-orders
          :loader="loadingGlobal"
          :orders="total_orders"
          class="my-2"
        ></app-total-orders>
        <app-total-sold
          :loader="loadingGlobal"
          :sold="total_sold"
          class="mt-2"
        ></app-total-sold>
        <app-total-sold-cost
          :loader="loadingGlobal"
          :sold="total_sold"
          :soldCost="total_sold_cost"
          class="mt-2"
        ></app-total-sold-cost>
      </v-col>
      <v-col cols="12" class="pt-0" v-if="salesList.length > 0">
        <v-card>
          <v-container>
            <p class="text-center mb-2 font-weight-bold">Listado de ventas</p>
            <v-select
              v-model="currentMonth"
              :items="recordsFilter"
              @change="changeFilterByMonth"
              item-text="title"
              item-value="value"
              return-object
              outlined
              :loading="loadingRecordFilter"
              dense
              hide-details
            ></v-select>
            <v-card
              v-for="(sale, index) in salesList"
              :key="index"
              class="my-2"
              outlined
            >
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" class="pb-2 text-center">
                    <p class="ma-0">
                      Report del
                      <span class="font-weight-medium">{{ sale.created }}</span>
                    </p>
                  </v-col>

                  <v-col
                    cols="12"
                    class="py-1 text-center"
                    v-if="sale.branch_offices.length > 1"
                  >
                    <v-card outlined>
                      <div
                        v-for="branch in sale.branch_offices"
                        :key="branch.id"
                      >
                        <p class="ma-0 py-1">{{ branch.branch_name }}</p>
                        <v-divider></v-divider>
                        <v-container class="d-flex justify-space-between py-0">
                          <div class="text-center">
                            <p class="caption ma-0 font-weight-medium">
                              Ordenes
                            </p>
                            <p class="caption my-1">
                              {{ branch.total_orders }}
                            </p>
                          </div>
                          <div class="text-center">
                            <p class="caption ma-0 font-weight-medium">
                              Productos
                            </p>
                            <p class="caption my-1">
                              {{ branch.total_products }}
                            </p>
                          </div>
                          <div class="text-center">
                            <p class="caption ma-0 font-weight-medium">
                              Ventas
                            </p>
                            <p class="caption my-1">
                              $ {{ branch.total_sold }}
                            </p>
                          </div>
                        </v-container>
                      </div>
                    </v-card>
                  </v-col>
                  <v-col cols="10" offset="1" class="px-0 pt-0">
                    <div class="d-flex justify-space-between">
                      <p class="ma-0 font-weight-medium">Ordenes</p>
                      <p class="ma-0 font-weight-bold">
                        {{ sale.total_orders }}
                      </p>
                    </div>
                    <div class="d-flex justify-space-between">
                      <p class="ma-0 font-weight-medium">Productos</p>
                      <p class="ma-0 font-weight-bold">
                        {{ sale.total_products }}
                      </p>
                    </div>
                    <div class="d-flex justify-space-between">
                      <p class="ma-0 font-weight-medium">Total en ventas</p>
                      <p class="ma-0 font-weight-bold">
                        <span class="font-weight-light">$</span>
                        {{ sale.total_sold }}
                      </p>
                    </div>
                    <div class="d-flex justify-space-between">
                      <p class="ma-0 font-weight-medium">Total en ganancias</p>
                      <p class="ma-0 font-weight-bold">
                        <span class="font-weight-light mr-1">$</span>
                        <span class="success--text">{{
                          sale.total_sold - sale.total_sold_cost
                        }}</span>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <div v-if="salesList.length > 0" class="text-center">
              <v-btn
                color="teal"
                dark
                v-if="!noMoreData && salesList.length > 4"
                @click="getNext"
                small
                :loading="loadingMoreData"
                >Cargar mas ventas</v-btn
              >
              <p v-else class="ma-0 caption">No hay mas datos por mostrar :)</p>
            </div>
          </v-container>
        </v-card>
      </v-col>
      <v-col cols="12" class="text-center" v-else>
        <p>Todavia no cuentas con reportes</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { database } from "@/config/firebase";
import TotalProducts from "../components/sales/TotalProducts";
import TotalOrders from "../components/sales/TotalOrders";
import TotalSold from "../components/sales/TotalSold";
import TotalSoldCost from "../components/sales/TotalSoldCost";
import { errorMessage } from "@/services/MessageService";
import moment from "moment";

export default {
  data() {
    return {
      // Stats
      total_orders: 0,
      total_sold: 0,
      total_sold_cost: 0,
      total_products: 0,
      loadingGlobal: true,
      loadingMoreData: false,
      noMoreData: false,

      // List
      salesList: [],
      databaseRef: null,
      firstKnownKeyDatabase: "",

      // Filter Month
      currentMonth: {},
      recordsFilter: [],
      loadingRecordFilter: true
    };
  },
  beforeMount() {
    const nameMonth = this.capitalizeFirstLetter(
      moment()
        .format("MMMM")
        .toString()
    );
    const fullYear = moment().format("YYYY");
    const currentMonth = parseInt(moment().month()) + 1;
    const currentMonthSale = `${currentMonth}${moment().year()}`;
    this.currentMonth = {
      title: nameMonth + " " + fullYear,
      value: parseInt(currentMonthSale)
    };
    this.getRecordFilter();
    this.getGlobalSales(currentMonthSale);
    this.getBusinessSales(currentMonthSale);
  },
  methods: {
    async getBusinessSales(currentMonthSale) {
      let childrenKey = null;
      this.databaseRef = database(
        `analytics/sales/orders/${currentMonthSale}/list`
      );
      this.databaseRef
        .orderByKey()
        .limitToLast(5)
        .once("value")
        .then(snap => {
          snap.forEach(childSnap => {
            this.salesList.unshift(childSnap.val());
            if (!childrenKey) childrenKey = childSnap.key;
          });
          this.firstKnownKeyDatabase = childrenKey;
        });
    },
    getNext() {
      this.loadingMoreData = true;
      let childrenKey = null;
      if (this.firstKnownKeyDatabase) {
        this.databaseRef
          .orderByKey()
          .endAt(this.excludeKeyValueDatabase(this.firstKnownKeyDatabase))
          .limitToLast(5)
          .once("value")
          .then(snap => {
            if (!snap.exists()) this.noMoreData = true;
            snap.forEach(childSnap => {
              this.salesList.unshift(childSnap.val());
              if (!childrenKey) childrenKey = childSnap.key;
            });
            this.firstKnownKeyDatabase = childrenKey;
            this.loadingMoreData = false;
          });
      } else {
        this.noMoreData = true;
      }
    },
    excludeKeyValueDatabase(key) {
      const keyTransform = parseInt(key) - 1;
      return keyTransform.toString();
    },
    async getGlobalSales(currentMonthSale) {
      this.loadingGlobal = true;
      const globalStats = await database(
        `analytics/sales/orders/${currentMonthSale}/stats`
      ).once("value");
      if (globalStats.exists()) {
        this.total_orders = globalStats.val().total_orders || 0;
        this.total_sold = globalStats.val().total_sold || 0;
        this.total_sold_cost = globalStats.val().total_sold_cost || 0;
        this.total_products = globalStats.val().total_products || 0;
        this.loadingGlobal = false;
      } else {
        this.total_orders = 0;
        this.total_sold = 0;
        this.total_sold_cost = 0;
        this.total_products = 0;
        this.loadingGlobal = false;
      }
    },
    async getRecordFilter() {
      this.loadingRecordFilter = true;
      try {
        await database("filters/filter_by_month").once(
          "value",
          async snapshot => {
            if (snapshot.exists()) {
              const records = snapshot.val();
              this.recordsFilter = records;
              this.loadingRecordFilter = false;
            } else {
              this.loadingRecordFilter = false;
            }
          }
        );
      } catch (error) {
        errorMessage({
          message:
            "Upss algo salio mal al obtener la información de los filtros. Code:" +
            error
        });
      }
    },
    async changeFilterByMonth(currentMonth) {
      this.salesList = [];
      this.total_orders = 0;
      this.total_sold = 0;
      this.total_products = 0;
      this.getBusinessSales(currentMonth.value);
      this.getGlobalSales(currentMonth.value);
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  components: {
    AppTotalProducts: TotalProducts,
    AppTotalOrders: TotalOrders,
    AppTotalSold: TotalSold,
    AppTotalSoldCost: TotalSoldCost
  }
};
</script>

<style></style>
