<template>
  <v-card
    color="#4693b1"
    dark
    height="58"
    class="d-flex align-center justify-space-between"
  >
    <div class="px-3">
      <p class="ma-0 caption mt-2" style="line-height: 8px;">
        Total de ordenes realizadas
      </p>
      <p class="text-h5 ma-0 pb-0 pl-1">
        <v-progress-circular
          indeterminate
          size="21"
          v-if="loader"
        ></v-progress-circular>
        <span v-else>{{ orders }}</span>
      </p>
    </div>
    <div class="px-3">
      <v-icon size="30">request_page</v-icon>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    orders: {
      type: Number,
      default: () => 0
    },
    loader: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style></style>
